import { Injectable } from '@angular/core';

export interface ToastInfo {
  header: string;
  body: string;
  className: string;
  delay?: number;
}

export enum ToastType {
  Danger = 'text-bg-danger',
  Dark = 'text-bg-dark',
  Info = 'text-bg-info',
  Primary = 'text-bg-primary',
  Secondary = 'text-bg-secondary',
  Success = 'text-bg-success',
  Warning = 'text-bg-warning',
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: ToastInfo[] = [];

  show(header: string, body: string, className: string = ToastType.Secondary) {
    this.toasts.push({ header, body, className });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t != toast);
  }
}
