import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { languageData, englishTexts } from '../core/translate/language';
import { BehaviorSubject, Observable, forkJoin, map } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

export const LANGUAGE_KEY = 'lang';
export const API_KEY =
  'https://translation.googleapis.com/language/translate/v2';

export enum LanguageEnum {
  En = 'en',
  Es = 'es',
  // Pt = 'pt',
  // Zh = 'zh-CN',
  // It = 'it',
}
export const LanguageList: string[] = Object.keys(LanguageEnum);

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  // ! COMPONENTS THAT USE SERVICE
  // QuestionnaireFormComponent
  private languageObservable: BehaviorSubject<string> =
    new BehaviorSubject<string>('en');
  onLanguageChange: Observable<string> = this.languageObservable.asObservable();

  data: any = languageData;

  targetLanguage: string = LanguageEnum.Es;

  constructor(private http: HttpClient, private storage: LocalStorageService) {}

  // persistent language change
  initLanguage() {
    const lang = this.storage.get<string>(LANGUAGE_KEY);

    if (lang) {
      this.targetLanguage = lang.toLowerCase();
    } else {
      const browser = navigator.language;

      this.targetLanguage = LanguageList.includes(browser)
        ? browser
        : LanguageEnum.En;

      this.storage.set(LANGUAGE_KEY, this.targetLanguage);
    }

    this.translateService();
  }

  changeLang(lang: string) {
    this.targetLanguage = lang.toLocaleLowerCase();
    this.storage.set(LANGUAGE_KEY, this.targetLanguage);
    this.languageObservable.next(this.targetLanguage);

    this.translateService();
  }

  splitString(arr: string[]): string[][] {
    const splitArray = [];
    const total = arr.length;
    const arraySize = environment.googleLimit;

    for (let i = 0; i < total; i += arraySize) {
      const nArray = arr.slice(i, i + arraySize);
      splitArray.push(nArray);
    }

    return splitArray;
  }

  translate(toTranslate: string[]) {
    const texts: string[] = toTranslate.filter((text) => text !== null);

    const options = {
      headers: {
        'X-Skip-Interceptor': 'true',
      },
    };

    const stringsArray: string[][] =
      texts.length > environment.googleLimit
        ? this.splitString(texts)
        : [texts];

    const serviceCalls = stringsArray.map((strings, index) => {
      const body = {
        q: strings,
        target: this.targetLanguage,
      };

      return this.http
        .post(`${API_KEY}?key=${environment.googleApi}`, body, options)
        .pipe(map((response) => ({ response, index })));
    });

    return forkJoin(serviceCalls).pipe(
      map((responsesWithIndex: any[]) => {
        responsesWithIndex.sort((a, b) => a.index - b.index);

        const translated = responsesWithIndex
          .map(({ response }) =>
            response.data.translations.map((t: any) => t.translatedText)
          )
          .flat();

        return translated;
      })
    );
  }

  translateService() {
    if (this.targetLanguage === LanguageEnum.En) {
      this.restoreTranslate();
      return;
    }

    const texts: string[] = this.extractStrings(englishTexts);

    this.translate(texts).subscribe({
      next: (response: any) => {
        if (response) {
          this.setStringToObject(this.data, response);
          this.data.header.language = this.targetLanguage.toUpperCase();
        } else {
          this.restoreTranslate();
        }
      },
      error: (error) => {
        console.error(error);

        this.restoreTranslate();
      },
    });
  }

  restoreTranslate() {
    const texts = this.extractStrings(englishTexts);
    this.setStringToObject(this.data, texts);
    this.data.header.language = this.targetLanguage.toUpperCase();
  }

  setStringToObject(obj: any, texts: string[]) {
    let index = 0;

    for (const key in obj) {
      for (const textKey in obj[key]) {
        if (texts[index]) obj[key][textKey] = this.decodeEntities(texts[index]);

        index += 1;
      }
    }
  }

  getObjString(langObj: any) {
    const result: string[] = [];

    for (const key in langObj) {
      for (const textKey in langObj[key]) {
        if (typeof langObj[key][textKey] === 'string')
          result.push(langObj[key][textKey]);
      }
    }

    return result;
  }

  extractStrings(obj: any): string[] {
    const result: string[] = [];

    const traverse = (o: any) => {
      for (const key in o) {
        if (typeof o[key] === 'string') {
          result.push(o[key]);
        } else if (typeof o[key] === 'object' && o[key] !== null) {
          traverse(o[key]);
        }
      }
    };

    traverse(obj);

    return result;
  }

  decodeEntities(input: string): string {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent || '';
  }
}
