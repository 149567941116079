import { Injectable } from '@angular/core';
import { general } from '../core/translate/language';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  languageText = general;
  private loading: boolean = false;
  private _label: string = this.languageText.loading;

  constructor() {}

  start() {
    setTimeout(() => {
      this.loading = true;
    }, 0);
  }

  stop() {
    setTimeout(() => {
      this.loading = false;
    }, 0);
  }

  setLoading(loading: boolean) {
    setTimeout(() => {
      this.loading = loading;
    }, 0);
  }

  getLoading(): boolean {
    return this.loading;
  }

  public get label(): string {
    return this._label;
  }
  public set label(value: string) {
    this._label = value;
  }
}
