import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './components/form/form.component';
import { NumericInputDirective } from './directives/numeric-input.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import {
  NgbAlert,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { LazyImageComponent } from './components/lazy-image/lazy-image.component';
import { ZeroDatePipe } from './pipes/zero-date.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ToastsComponent } from './components/toasts/toasts.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { MainModalComponent } from './components/main-modal/main-modal.component';
import { TranslationAlertComponent } from './components/translation-alert/translation-alert.component';

@NgModule({
  declarations: [
    // Components
    TranslationAlertComponent,
    FormComponent,
    LazyImageComponent,
    ToastsComponent,
    ConfirmModalComponent,
    MainModalComponent,
    // Directives
    NumericInputDirective,
    ZeroDatePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgbModalModule,
    NgbToastModule,
    NgbAlert,
    NgCircleProgressModule.forRoot(),
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModule,
  ],
  exports: [
    // Components
    FormComponent,
    LazyImageComponent,
    ToastsComponent,
    ConfirmModalComponent,
    TranslationAlertComponent,
    MainModalComponent,

    // Directives
    NumericInputDirective,
    ZeroDatePipe,

    // Modules
    NgSelectModule,
    FormsModule,
    NgbModalModule,
    NgbAlert,
    NgCircleProgressModule,
    NgbToastModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModule,
  ],
})
export class CoreModule {}
