import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptorInterceptor implements HttpInterceptor {
  constructor(private user: UserService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set('Content-Type', 'application/json'),
    });
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    if (
      request.headers.has('X-Skip-Interceptor') &&
      request.headers.get('X-Skip-Interceptor') === 'true'
    ) {
      request = request.clone({
        headers: request.headers.delete('X-Skip-Interceptor'),
      });
    } else {
      let userToken = this.user.getToken();

      if (userToken !== null) {
        request = request.clone({
          headers: request.headers.set(
            this.user.logged ? 'Authorization' : 'uuid',
            this.user.logged ? `Bearer ${userToken}` : userToken
          ),
        });
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 404 || response.error.status == 404) {
          alert(response.error.message.join('\n'));
        }

        if (response.status == 401 || response.error.status == 401) {
          this.user.clearSession();
          this.router.navigate(['/'], { replaceUrl: true });
        }

        return throwError(response);
      })
    );
  }
}
