import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

class Response {
  data: any;
  status!: number;
  code!: number;
  message: string[] = [];
}

class Param {
  service: string = '';
  method: string = 'get';
  params: any = {};
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private user: UserService) {}

  private built(service: string): string {
    return environment.endpoint + service;
  }

  private parse(data: any): Response {
    let response = new Response();

    response.status = data.status;
    response.message = data.message;
    response.data = data.data;

    return response;
  }

  get(service: string, params: any = {}): Observable<any> {
    return this.http.get<Response>(this.built(service), params).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // ! api callback method
  execute(
    service: string,
    success: (response: any) => void,
    failed: (error: any) => void,
    method: string = 'get',
    params: any = {},
    useBearer: boolean = false
  ): void {
    this.call(service, method, params, useBearer).subscribe(
      (response) => {
        if (response.status === 200) {
          success(response);
        } else {
          failed(response.message);
        }
      },
      (error) => {
        failed(error);
      }
    );
  }

  external(
    service: string,
    method = 'get',
    params: any = {}
  ): Observable<Response> {
    let request$: Observable<any>;

    switch (method) {
      case 'post':
        request$ = this.http.post(service, params);
        break;
      case 'put':
        request$ = this.http.put(service, params);
        break;
      case 'delete':
        request$ = this.http.delete(service, params);
        break;
      default:
        request$ = this.http.get(service, params);
        break;
    }

    return request$.pipe(map((data: any) => this.parse(data)));
  }

  call<T>(
    service: string,
    method = 'get',
    params: any = {},
    useBearer: boolean = false,
    skipAuth: boolean = false
  ): Observable<Response> {
    let userToken = this.user.getToken();

    const headers: any = {};

    if (skipAuth) {
      headers['X-Skip-Interceptor'] = 'true';
    } else {
      headers[useBearer ? 'Authorization' : 'uuid'] = useBearer
        ? `Bearer ${userToken}`
        : this.user.uuid;
    }

    const options = { headers };

    const serviceParams = method === 'get' ? { params } : params;

    let request$: Observable<any>;

    switch (method) {
      case 'post':
        request$ = this.http.post(this.built(service), serviceParams, options);
        break;
      case 'put':
        request$ = this.http.put(this.built(service), serviceParams, options);
        break;
      case 'delete':
        request$ = this.http.delete(this.built(service), options);
        break;
      default:
        request$ = this.http.get(this.built(service), options);
        break;
    }

    return request$.pipe(map((data: any) => this.parse(data)));
  }

  open(service: string, finish: (res: boolean) => void, isFull = false) {
    let url = service;

    if (!isFull) {
      url = this.built(service);
    }

    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http.get<HttpResponse<any>>(url, options).subscribe({
      next: (response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          return;
        }

        let hdrs = response.headers.get('Content-Disposition');
        let file = 'document';
        if (hdrs != null) {
          const split = hdrs.split('filename=');

          if (split.length > 0) {
            file = split[1].split('"').join('');
          }
        }

        const u = window.URL.createObjectURL(response.body);
        finish(true);
        window.open(u, '_blank');
      },
      error: (error: any) => {
        console.error(error);
        finish(false);
      },
    });
  }

  downloadSimple(service: string) {
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http
      .get<HttpResponse<any>>(this.built(service), options)
      .subscribe((response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          return;
        }

        let hdrs = response.headers.get('Content-Disposition');
        let file = 'document';
        if (hdrs != null) {
          const split = hdrs.split('filename=');
          if (split.length > 0) {
            file = split[1].split('"').join('');
          }
        }

        const u = window.URL.createObjectURL(response.body);
        const l = document.createElement('a');
        l.href = u;
        l.download = file;
        l.click();
      });
  }

  download(
    service: string,
    finish: (exit: boolean) => void,
    isFull = false,
    name = 'document.pdf',
    type = 'pdf'
  ) {
    let url = service;

    if (!isFull) {
      url = this.built(service);
    }

    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http.get<HttpResponse<any>>(url, options).subscribe({
      next: (response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          finish(false);

          return;
        }

        let hdrs = response.headers.get('Content-Disposition');
        let file = 'document';
        if (hdrs != null) {
          let split = hdrs.split('filename=');

          if (split.length > 0) {
            file = split[1].split('"').join('');
          }
        } else {
          file += '.' + type;
        }

        let u = window.URL.createObjectURL(response.body);
        let l = document.createElement('a');
        l.href = u;
        l.download = name;
        l.click();
        finish(true);
      },
      error: (error: any) => {
        console.error(error);

        let errorMsg = error.error.message
          ? error.error.message
          : error.message;
        alert(errorMsg);
        finish(false);
      },
    });
  }
}
