<div class="app">
  <app-header></app-header>

  <main [ngClass]="{ loading: loadingService.getLoading() }">
    <router-outlet></router-outlet>
  </main>

  <div class="spinner-overlay" *ngIf="loadingService.getLoading()">
    <div class="spinner"></div>

    <div
      class="spinner-label"
      *ngIf="loadingService.label"
      role="status"
      aria-live="assertive"
      tabindex="0"
    >
      <p class="h5">
        {{ loadingService.label }}
      </p>
    </div>
  </div>
  <app-footer [ngClass]="{ loading: loadingService.getLoading() }"></app-footer>
</div>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
