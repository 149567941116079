import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { header } from 'src/app/core/translate/language';
import { ApiService } from 'src/app/services/api.service';
import {
  LanguageList,
  TranslationService,
} from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isMenuCollapsed: boolean = true;
  isLoggedIn: boolean = false;
  currentQs: string = 'your-digital-journey';
  languageText = header;
  languages: string[] = LanguageList;

  constructor(
    public translationService: TranslationService,
    private user: UserService,
    private api: ApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = this.user.logged;

    this.user.profileChanged.subscribe((loggedIn) => {
      this.isLoggedIn = this.user.logged;
    });
  }

  changeLan(language: string) {
    this.translationService.changeLang(language);
  }

  closeMenu() {
    this.isMenuCollapsed = true;
  }

  logOut() {
    this.user.clearSession();
    this.isLoggedIn = false;
    this.router.navigate(['/auth/login']);
  }

  getQuestionnaires() {
    this.api.call('api/questionnaires').subscribe({
      next: (response) => {
        if (response.status === 200 && response.data) {
          if (response.data[0] && response.data[0].slug) {
            this.currentQs = response.data[0].slug;
          }
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
