import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private profileObservable: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  profileChanged: Observable<boolean> = this.profileObservable.asObservable();

  constructor(private storage: LocalStorageService) {}

  get logged(): boolean {
    const token = this.getToken();

    if (!token) return false;

    return token.length > 0;
  }

  // TOKEN KEY
  getToken(): string {
    return this.storage.get<string>('token') ?? '';
  }

  setToken(token: string) {
    this.storage.set('token', token);
    this.profileObservable.next(true);
  }

  // ID KEY
  get id(): number {
    return this.storage.get<number>('id') ?? -1;
  }
  set id(id: number) {
    this.storage.set('id', id);
  }

  // TOKEN KEY
  get uuid(): string {
    return this.storage.get<string>('uuid') ?? '';
  }
  set uuid(uuid: string) {
    this.storage.set('uuid', uuid);
  }

  clearSession() {
    this.setToken('');
    this.uuid = '';
    this.profileObservable.next(false);
    this.storage.cleanStorage();
  }
}
