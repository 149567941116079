import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-autologin',
  template: ``,
  styles: [],
})
export class AutologinComponent {
  constructor(
    private user: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.start();

    const token = this.route.snapshot.params['token'];

    if (token) {
      this.user.clearSession();
      this.user.setToken(token);
      this.router.navigate(['/app/all-questionnaires'], { replaceUrl: true });

      return;
    }

    this.router.navigate(['/'], { replaceUrl: true });
  }
}
