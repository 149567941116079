import { inject } from '@angular/core';
import { CanActivateFn, CanActivateChildFn, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

export const publicGuard: CanActivateFn = (route, state) => {
  const authorized = !inject(UserService).logged;

  if (authorized) {
    return true;
  } else {
    inject(Router).navigate(['/app/all-questionnaires']);
    return false;
  }
};

export const publicGuardChild: CanActivateChildFn = (route, state) => {
  const authorized = !inject(UserService).logged;

  if (authorized) {
    return true;
  } else {
    inject(Router).navigate(['/app/all-questionnaires']);
    return false;
  }
};
