<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="'toastContainer ' + toast.className"
  [header]="toast.header"
  [autohide]="true"
  [delay]="toast.delay || 3000"
  (hiddden)="toastService.remove(toast)"
  aria-label="Toast Container"
>
  {{ toast.body }}
</ngb-toast>
