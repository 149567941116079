import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AlertModal } from '../translation-alert/alert.modal';
import { LoadingService } from 'src/app/services/loading.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent {
  endpoint: string = '';
  method: string = 'post';
  useBearer: boolean = false;
  skiptAuth: boolean = false;
  alertData: AlertModal | null = null;
  data: any = {};

  constructor(
    protected api: ApiService,
    protected loadingService: LoadingService,
    protected translationService: TranslationService
  ) {
    this.settings();
  }

  settings() {}

  isValid(): boolean {
    return true;
  }

  getParams() {
    return this.data;
  }

  submit() {
    if (this.loadingService.getLoading() || !this.isValid()) return;

    this.startLoading();

    const params = this.getParams();

    this.api
      .call(this.endpoint, this.method, params, this.useBearer, this.skiptAuth)
      .subscribe({
        next: (response) => {
          if (response.status === 200) {
            this.success(response);
          } else {
            this.failed(response.message.join(', '));
          }
        },
        error: (error) => {
          this.failed(error);
        },
      });
  }

  success(response: any) {
    this.stopLoading();
  }

  failed(error: any) {
    this.handleAlert(error);

    this.stopLoading();
  }

  handleAlert(message: string, type: string = 'danger') {
    this.alertData = new AlertModal();

    this.alertData = {
      message,
      type,
    };
  }

  startLoading() {
    this.loadingService.start();
  }

  stopLoading() {
    this.loadingService.stop();
  }
}
