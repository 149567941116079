<app-main-modal [title]="title" (close)="close()">
  <div class="row py-4" content>
    <div class="col-12 mb-4">
      <h1 class="h5" [innerHTML]="message"></h1>
    </div>
  </div>

  <div class="w-100" footer>
    <div class="row justify-content-evenly">
      <div class="col-4 mx-auto text-center" *ngIf="hasCancel">
        <button
          type="button"
          class="btn"
          [ngClass]="cancelClass"
          (click)="close()"
          (keyup.enter)="close()"
          [attr.aria-label]="cancelBtn"
        >
          {{ cancelBtn }}
        </button>
      </div>

      <div class="col-4 mx-auto text-center">
        <button
          type="button"
          class="btn"
          [ngClass]="confirmClass"
          (click)="confirm()"
          (keyup.enter)="close()"
          [attr.aria-label]="confirmBtn"
        >
          {{ confirmBtn }}
        </button>
      </div>
    </div>
  </div>
</app-main-modal>
