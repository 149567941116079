<div
  #alertRef
  *ngIf="alert"
  class="col-12 my-3 my-lg-4 show-alert"
  role="alert"
  aria-live="polite"
>
  <ngb-alert [type]="alert.type" [dismissible]="true" (closed)="closeAlert()">
    <div [innerHTML]="alert.message"></div>
  </ngb-alert>
</div>
