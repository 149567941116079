export class AlertModal {
  type: string = 'success';
  message: string = '';

  static built(type: string, message: string) {
    const alert = new AlertModal();
    alert.type = type;
    alert.message = message;
    return alert;
  }
}
