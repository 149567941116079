export const englishTexts = {
  general: {
    loading: 'Loading...',
  },
  header: {
    about: 'About the Program',
    whoWeAre: 'Who We Are',
    library: 'Library',
    resources: 'Resources',
    title: 'Official website of the City of San Antonio',
    contact: 'Contact us',
    getStarted: 'Get Started!',
    account: 'My Account',
    settings: 'Settings',
    logout: 'Log out',
    language: 'EN',
    titlelanguage: 'Select a language',
    submissions: 'My Submissions',
  },
  login: {
    userName: 'Email Address',
    password: 'Password',
    forgot: 'I forgot my password',
    button: 'LOGIN',
  },
  recover: {
    title: 'Change your password',
    new: 'New password',
    newPlaceholder: 'Type in your new password...',
    confirm: 'Re-type password',
    confirmPlaceholder: 'Re-Type in your new password...',
    login: 'Log in',
    changeBtn: 'CHANGE',
    email: 'Email',
    emailPlaceholder: 'Type in your email...',
  },
  footer: {
    title: 'Digital Presence Program',
    copyright: 'Copyright &copy; 2023 City of San Antonio',
    equity: 'Office of Equity',
    ada: 'ADA Compliance',
    records: 'Open Records',
    contact: 'Contact Us',
    privacy: 'Privacy Policy & Disclaimer',
    program: 'Program Agreement',
  },
  getStarted: {
    getStarted: 'Get Started!',
    title: 'Your Questionnares',
    before: 'Before We Get Started',
    list1:
      'If you have multiple businesses, please have in mind ONLY ONE business as you fill out the questionnaire. You will have the opportunity to resubmit for additional businesses once you complete the questionnaire.',
    list2:
      'You will be asked a series of questions related to your business and your online marketing efforts in 4 different areas: Business Details, Website, Social Media, and Content.',
    list3:
      'Remember the email address you provide to us as that will be your login at sanantonio.digital. You will receive a welcome email with a password (remember you may need to check your spam or promotions folder).',
    list4:
      'Don&rsquo;t worry if you cannot complete the audit right now. You will be able to save your progress and return later to finish it.',
    list5:
      'By continuing with the audit, I agree to be bound by all the program rules and guidelines as described on the homepage and Program Agreement.',
    button: 'I Understand, Let&rsquo;s Get Started!',
  },
  account: {
    access: 'Access',
    title: 'My Account',
    name: 'Name',
    lastname: 'Last Name',
    email: 'Email address',
    button: 'Save',
    password: 'New Password',
    confirm: 'Confirm Password',
    error: 'Required field',
  },
  questionnaireForm: {
    title: 'Questionnaire',
    titleSentence:
      'Please answers the questions to the best of your knowledge! When in doubt, answer I don&rsquo;t know.',
    progress: 'SECTION PROGRESS...',
    back: 'Back',
    next: 'Save & Continue',
    continue: 'Continue',
    here: 'Here',
    generate: 'Generate',
    your: 'Your Report',
    overwhelmedTitle: 'Need more time?',
    overwhelmedText:
      'You can save your progress and finish later. You have to complete the questionnaire to submit',
    button: 'Save My Progress',
    submit: 'Submit',
    questionnaires: 'My questionnaires',
    completed: 'You have completed the questionnaire!',
  },
  questionnaires: {
    title1: 'Hello',
    title2: 'these are your business profiles:',
    businessName: 'Business Name',
    section: 'Section',
    addANewBusiness: 'Add A New Business',
    newText: 'This will start a new questionnaire',
    newLink: 'Get started',
    deleteQs: 'Delete questionnaire',
    deleteTitle: 'Questionnaire deletion',
    confirmDeletation: 'Are you sure you want to delete this questionnaire?',
    confirm: 'CONFIRM',
    cancel: 'CANCEL',
  },
  report: {
    your: 'here is your report!',
    print: 'Print Report',
    percentages:
      'These percentages represent the areas where your business needs help based on your answers.',
    summary:
      'Keep reading for next steps and highlights. And remember a free one-hour consultation with a marketing expert awaits!',
    step2: 'STEP 2',
    step2Text: 'Here is a quick summary.',
    libraryTitle: 'Don&rsquo;t miss our library of resources',
    libraryContent:
      'Learn from experts about your website, social and content, get tips on how to make your digital presence better!',
    libraryVisit: 'Visit Library',
    dontUnderstand: 'What&rsquo;s Next?',
    understandText:
      'Don&rsquo;t worry you qualify for a free one hour consultation with a local digital expert who will explain everything. Be on the lookout for an email to schedule your time with them!',
    noScheduleText:
      'Be on the lookout for an email to schedule your time with a local digital expert who will explain everything on this report.',
    schedule: 'Schedule Here',
    step3: 'STEP 3',
    step3Text:
      'Based on your answers here are some action items to take your business to the next level',
    highlights: 'Additional Tips',
    highlightsText:
      'Based on your overall answers, here are also some overall highlights for you to consider as well',
    qualify: 'You may qualify for free work!',
    qualifyText:
      'Lastly, remember you may qualify to receive no cost work, which could translate to a full website, digital marketing, content, and more.',
    qualifyBot: 'We will evaluate and reach out soon!',
    report: 'Report',
    error:
      'Ooops! There was an issue generating your report. Please reach out to us at info@sanantonio.digital. Once resolved, we will notify you with your completed report.',
  },
  questionnaireActions: {
    scheduled: 'Scheduled',
    view: 'View Form',
    continue: 'Continue',
    processing: 'Processing',
    viewReport: 'View Audit Report',
  },
  questionnaireStatus: {
    assigned: 'ASSIGNED',
    complete: 'COMPLETE',
    created: 'CREATED',
    declined: 'DECLINED',
    inProgress: 'IN PROGRESS',
    processing: 'PROCESSING',
    scheduled: 'SCHEDULED',
    unassigned: 'UNASSIGNED',
  },
  questionnaireCard: {
    edit: 'EDIT',
    view: 'VIEW',
    progress: 'PROGRESS...',
    here: 'You are here',
  },
  feedback: {
    collapText: 'Read Actions Items',
    collapHighlights: 'Read Highlights',
    closeText: 'Close',
  },
  questionnaireConfirm: {
    title: 'Congratulations on completing the questionnaire!',
    text: 'Please go ahead and submit your responses and generate your personal report. Remember, once you submit your answers you can&rsquo;t make modifications.',
    generate: 'Generate My Report',
    editing: 'Continue Editing',
  },
  scheduleSelector: {
    accept: 'Accept',
    cancel: 'Cancel',
    confirm: 'Confirm',
    confirmTitle: 'Confirm consultation date',
    noDatesText:
      'These dates do not work for me but I still want to meet with a professional to review and learn more. Please send me additional dates.',
    sendDates: 'Send New Dates',
    date: 'Date',
    noDates: 'No dates available...',
    title: 'Select consultation date',
  },
  emailWidget: {
    edit: 'Edit',
    continue: 'Continue',
    editLabel: 'Edit email',
    continueLabel: 'Validate email',
  },
  textfieldWidget: {
    continue: 'Continue',
    continueLabel: 'Continue Form',
    submissions: 'My Submissions',
    validate: 'Validate',
    validateLabel: 'Validate business name',
  },
};

// ! IMPORTANT: The order of properties will match that of the English texts.
export const languageData = {
  general: { ...englishTexts.general },
  header: { ...englishTexts.header },
  login: { ...englishTexts.login },
  recover: { ...englishTexts.recover },
  footer: { ...englishTexts.footer },
  getStarted: { ...englishTexts.getStarted },
  account: { ...englishTexts.account },
  questionnaireForm: { ...englishTexts.questionnaireForm },
  questionnaires: { ...englishTexts.questionnaires },
  report: { ...englishTexts.report },
  questionnaireActions: { ...englishTexts.questionnaireActions },
  questionnaireStatus: { ...englishTexts.questionnaireStatus },
  questionnaireCard: { ...englishTexts.questionnaireCard },
  feedback: { ...englishTexts.feedback },
  questionnaireConfirm: { ...englishTexts.questionnaireConfirm },
  scheduleSelector: { ...englishTexts.scheduleSelector },
  emailWidget: { ...englishTexts.emailWidget },
  textfieldWidget: { ...englishTexts.textfieldWidget },
};

export const {
  general,
  header,
  login,
  recover,
  footer,
  getStarted,
  account,
  questionnaireForm,
  questionnaires,
  report,
  questionnaireActions,
  questionnaireStatus,
  feedback,
  questionnaireConfirm,
  scheduleSelector,
  questionnaireCard,
  emailWidget,
  textfieldWidget,
} = languageData;
