import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumericInput]',
})
export class NumericInputDirective {
  private isNumber(value: string) {
    return String(value).match(new RegExp(/^\d+$/));
  }

  private specialKeys = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
  ];

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (event.key == ' ') {
      event.preventDefault();
      return;
    }

    const next: string = event.key;

    if (next && !this.isNumber(next)) {
      event.preventDefault();
      return;
    }

    return;
  }
}
