<nav class="navbar navbar-expand-lg header">
  <div class="main-bar">
    <div class="container d-flex align-items-center">
      <img src="assets/images/bitmap.png" height="20" alt="San Antonio" />

      <a
        class="text-white"
        href="https://www.sa.gov"
        target="_blank"
        rel="noopener noreferrer"
      >
        <small class="main-link ms-2">
          {{ languageText.title }}
        </small>
      </a>

      <li
        ngbDropdown
        class="nav-item mb-2 mb-sm-0 ms-auto d-none d-lg-block"
        role="menuitem"
      >
        <button
          id="languageDropdown"
          type="button"
          class="btn btn-outline-dark btn-3d py-2 main-language"
          ngbDropdownToggle
          [attr.aria-label]="languageText.titlelanguage"
          tabindex="0"
        >
          <small class="strong me-3">
            {{ languageText.language === "EN" ? "English" : "Español" }}
          </small>
        </button>

        <ul
          ngbDropdownMenu
          class="language-dropdown btn-3d"
          role="menu"
          tabindex="0"
        >
          <li role="menuitem" *ngFor="let lang of languages">
            <button
              ngbDropdownItem
              (click)="changeLan(lang)"
              (keyup.enter)="changeLan(lang)"
              [attr.aria-label]="
                'Select ' +
                (lang === 'En' ? 'English' : 'Español') +
                ' language'
              "
              tabindex="0"
            >
              {{ lang === "En" ? "English" : "Español" }}
            </button>
          </li>
        </ul>
      </li>
    </div>
  </div>

  <div class="container second-bar px-3 px-lg-4" role="navigation">
    <a class="navbar-brand" href="https://sanantonio.digital/" tabindex="0">
      <div class="header-logos">
        <img src="assets/images/logo.png" alt="San Antonio Logo" />

        <div class="logo-divider d-none d-lg-block mx-2 mx-sm-3"></div>

        <div class="d-flex flex-column d-none d-lg-block">
          <h1 class="sr-only">Digital Presence Program</h1>

          <div class="d-flex flex-column my-auto" aria-hidden="true">
            <span class="font-h">Digital</span>
            <span class="font-h">Presence</span>
            <span class="font-h">Program</span>
          </div>
        </div>
      </div>
    </a>

    <div
      ngbDropdown
      class="nav-item ms-auto me-3 mb-2 mb-sm-0 d-lg-none"
      role="menuitem"
    >
      <button
        id="languageDropdown"
        type="button"
        class="btn btn-outline-dark btn-3d py-0 main-language"
        ngbDropdownToggle
        [attr.aria-label]="languageText.titlelanguage"
        tabindex="0"
      >
        {{ languageText.language === "EN" ? "English" : "Español" }}
      </button>

      <ul
        ngbDropdownMenu
        class="language-dropdown btn-3d"
        role="menu"
        tabindex="0"
      >
        <li role="menuitem" *ngFor="let lang of languages">
          <button
            ngbDropdownItem
            (click)="changeLan(lang)"
            (keyup.enter)="changeLan(lang)"
            [attr.aria-label]="
              'Select ' + (lang === 'En' ? 'English' : 'Español') + ' language'
            "
            tabindex="0"
          >
            {{ lang === "En" ? "English" : "Español" }}
          </button>
        </li>
      </ul>
    </div>

    <button
      type="button"
      class="navbar-toggler py-2 me-sm-2"
      (click)="isMenuCollapsed = !isMenuCollapsed"
      (keyup.enter)="isMenuCollapsed = !isMenuCollapsed"
      tabindex="0"
      [attr.aria-expanded]="!isMenuCollapsed"
      aria-label="Toggle Navigation Menu"
    >
      <i class="fa-solid fa-bars"></i>
    </button>

    <nav
      [ngbCollapse]="isMenuCollapsed"
      class="collapse navbar-collapse pt-3 pt-lg-0"
    >
      <ul class="navbar-nav ms-auto" role="menu">
        <li class="nav-item mb-2 mb-sm-0 me-lg-3" role="menuitem">
          <a
            class="nav-link"
            href="https://sanantonio.digital/about/"
            [attr.aria-label]="languageText.about"
            tabindex="0"
          >
            {{ languageText.about }}
          </a>
        </li>

        <li class="nav-item mb-2 mb-sm-0 me-lg-3" role="menuitem">
          <a
            class="nav-link"
            href="https://sanantonio.digital/library/"
            [attr.aria-label]="languageText.library"
            tabindex="0"
          >
            {{ languageText.resources }}
          </a>
        </li>

        <li class="nav-item mb-2 mb-sm-0 me-lg-3" role="menuitem">
          <a
            class="nav-link"
            href="https://sanantonio.digital/who-we-are/"
            [attr.aria-label]="languageText.whoWeAre"
            tabindex="0"
          >
            {{ languageText.whoWeAre }}
          </a>
        </li>

        <li class="nav-item mb-2 mb-sm-0 me-lg-3" role="menuitem">
          <a
            class="btn btn-outline-dark btn-3d"
            [routerLink]="isLoggedIn ? '/app/all-questionnaires' : '/app/home'"
            (click)="closeMenu()"
            (keyup.enter)="closeMenu()"
            [attr.aria-label]="languageText.getStarted"
            aria-live="polite"
            tabindex="0"
          >
            {{
              isLoggedIn ? languageText.submissions : languageText.getStarted
            }}
            <i class="fa-solid fa-arrow-right-long ms-3" aria-hidden="true"></i>
          </a>
        </li>

        <li
          class="nav-item mb-2 mb-sm-0 me-lg-3"
          ngbDropdown
          role="menuitem"
          *ngIf="isLoggedIn; else noLogged"
        >
          <button
            id="accountButton"
            type="button"
            role="link"
            class="btn btn-outline-dark btn-3d"
            tabindex="0"
            ngbDropdownToggle
          >
            {{ languageText.account }}
          </button>

          <ul
            ngbDropdownMenu
            aria-labelledby="accountButton"
            class="btn-3d"
            role="menu"
          >
            <li>
              <a
                ngbDropdownItem
                routerLink="/app/account"
                [attr.aria-label]="languageText.settings"
                tabindex="0"
              >
                {{ languageText.settings }}
              </a>
            </li>

            <li role="menuitem">
              <button
                ngbDropdownItem
                type="button"
                (click)="logOut()"
                [attr.aria-label]="languageText.logout"
                tabindex="0"
              >
                {{ languageText.logout }}
              </button>
            </li>
          </ul>
        </li>

        <ng-template #noLogged>
          <li class="nav-item mb-2 mb-sm-0 me-lg-3" role="menuitem">
            <a
              class="btn btn-outline-dark btn-3d"
              routerLink="/auth/login"
              (click)="closeMenu()"
              (keyup.enter)="closeMenu()"
              [attr.aria-label]="languageText.account"
              tabindex="0"
            >
              {{ languageText.account }}

              <i
                class="fa-solid fa-arrow-right-long ms-3"
                aria-hidden="true"
              ></i>
            </a>
          </li>
        </ng-template>
      </ul>
    </nav>
  </div>
</nav>
