<img
  [src]="imageUrl"
  [class]="'image-component ' + imageClass"
  [ngStyle]="{ display: loading ? '' : 'none' }"
  [alt]="alt"
  (load)="onLoad()"
  (error)="handleImageError()"
/>
<img
  *ngIf="!loading"
  src="assets/svg/loader.svg"
  class="image-component loader-image"
  alt="Loader image"
/>
