<footer class="footer">
  <div class="footer-logo">
    <div class="footer-content">
      <img
        src="assets/images/logo.png"
        class="mx-auto"
        alt="San Antonio Footer Logo"
      />

      <strong class="mt-3">
        {{ languageText.title }}
      </strong>
    </div>
  </div>

  <div class="container-xl px-0 my-0 pt-4 pt-lg-4">
    <div class="row align-items-center justify-content-center text-center m-0">
      <div class="col-lg-10 px-0 mx-auto">
        <div class="row justify-content-center">
          <div class="col-lg-auto link-col">
            <a
              class="text-dark"
              href="https://www.sanantonio.gov/Equity"
              target="_blank"
              rel="noopener noreferrer"
            >
              <small class="font-normal">{{ languageText.equity }}</small>
            </a>
          </div>

          <div class="col-auto px-0 d-none d-lg-block">
            <div class="logo-divider"></div>
          </div>

          <div class="col-lg-auto link-col">
            <a
              class="text-dark"
              href="https://www.sanantonio.gov/dao"
              target="_blank"
              rel="noopener noreferrer"
            >
              <small class="font-normal">{{ languageText.ada }}</small>
            </a>
          </div>

          <div class="col-auto px-0 d-none d-lg-block">
            <div class="logo-divider"></div>
          </div>

          <div class="col-lg-auto link-col">
            <a
              class="text-dark"
              href="https://www.sa.gov/Directory/Departments/CE/Open-Government-Request"
              target="_blank"
              rel="noopener noreferrer"
            >
              <small class="font-normal">{{ languageText.records }}</small>
            </a>
          </div>

          <div class="col-auto px-0 d-none d-lg-block">
            <div class="logo-divider"></div>
          </div>

          <div class="col-lg-auto link-col">
            <a
              class="text-dark"
              href="https://www.sanantonio.gov/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              <small class="font-normal">{{ languageText.contact }}</small>
            </a>
          </div>

          <div class="col-auto px-0 d-none d-lg-block">
            <div class="logo-divider"></div>
          </div>

          <div class="col-lg-auto link-col">
            <a
              class="text-dark"
              href="https://www.sanantonio.gov/disclaimer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <small class="font-normal">{{ languageText.privacy }}</small>
            </a>
          </div>

          <div class="col-auto px-0 d-none d-lg-block">
            <div class="logo-divider"></div>
          </div>

          <div class="col-lg-auto link-col">
            <a
              class="text-dark"
              href="https://sanantonio.digital/program-agreement"
              target="_blank"
              rel="noopener noreferrer"
            >
              <small class="font-normal">{{ languageText.program }}</small>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3 mt-lg-2">
        <small>{{ languageText.copyright }}</small>
      </div>
    </div>
  </div>
</footer>
