import {
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AlertModal } from 'src/app/questionnaire/models/AlertModal.model';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-translation-alert',
  templateUrl: './translation-alert.component.html',
  styleUrls: ['./translation-alert.component.scss'],
})
export class TranslationAlertComponent {
  englishText: string = '';
  @Input() alert: AlertModal | null = null;
  @ViewChild('alertRef') alertRef?: ElementRef;

  constructor(private translationService: TranslationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.alert) {
      this.englishText = this.alert.message;
      this.translateAlert();
    }
  }

  ngOnInit(): void {
    if (this.alert) {
      this.englishText = this.alert.message;
    }

    this.translationService.onLanguageChange.subscribe((language) => {
      this.translateAlert();
    });
  }

  translateAlert() {
    if (this.alert === null || this.translationService.targetLanguage === 'en')
      return;

    const texts = [this.alert.message];

    this.translationService.translate(texts).subscribe({
      next: (response) => {
        if (this.alert === null) return;

        if (response && response[0]) {
          this.alert.message = response[0];
        } else {
          this.alert.message = this.englishText;
        }
      },
      error: (error) => {
        if (this.alert === null) return;

        this.alert.message = this.englishText;
      },
    });
  }

  closeAlert() {
    this.alert = null;
  }
}
